var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"a-currency-conversion"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"secondary"},on:{"click":_vm.openDialog}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-currency-usd")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("currency_conversion_settings"))+" ")])]),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"a-currency-conversion-dialog"},[_c('v-card-title',{staticClass:"headline mb-4"},[_vm._v(" "+_vm._s(_vm.$t("currency_conversion_settings"))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(!_vm.loading)?[_c('v-card-text',[_c('v-data-table',{staticClass:"no-elevation periods-table",attrs:{"headers":_vm.headers,"items":_vm.periods_mapped,"options":{ sortBy: ['start_date'], sortDesc: [true] },"item-class":_vm.getDatatablePeriodsClass},scopedSlots:_vm._u([{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","dark":"","color":"#333333"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onDate = ref.on;
return [_c('div',_vm._g({on:{"click":function($event){return _vm.setPeriodToEdit([item.start_date, item.end_date])}}},Object.assign({}, onMenu, onDate)),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"secondary","small":""}},[_vm._v(" mdi-calendar-month-outline ")]),_c('b',[_vm._v(_vm._s(_vm.getFriendlyDate(item.start_date)))])],1)]}}],null,true),model:{value:(item.menu_start_opened),callback:function ($$v) {_vm.$set(item, "menu_start_opened", $$v)},expression:"item.menu_start_opened"}},[_c('v-date-picker',{attrs:{"scrollable":"","first-day-of-week":"1","allowed-dates":_vm.allowedPeriodsDates},on:{"change":function($event){return _vm.setPeriod(item)}},model:{value:(item.start_date),callback:function ($$v) {_vm.$set(item, "start_date", $$v)},expression:"item.start_date"}})],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("change_date"))+" ")])])]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","dark":"","color":"#333333"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onDate = ref.on;
return [_c('div',_vm._g({on:{"click":function($event){return _vm.setPeriodToEdit([item.start_date, item.end_date])}}},Object.assign({}, onMenu, onDate)),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"secondary","small":""}},[_vm._v(" mdi-calendar-month-outline ")]),_c('b',[_vm._v(_vm._s(_vm.getFriendlyDate(item.end_date)))])],1)]}}],null,true),model:{value:(item.menu_end_opened),callback:function ($$v) {_vm.$set(item, "menu_end_opened", $$v)},expression:"item.menu_end_opened"}},[_c('v-date-picker',{attrs:{"scrollable":"","first-day-of-week":"1","allowed-dates":_vm.allowedPeriodsDates},on:{"change":function($event){return _vm.setPeriod(item)}},model:{value:(item.end_date),callback:function ($$v) {_vm.$set(item, "end_date", $$v)},expression:"item.end_date"}})],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("change_date"))+" ")])])]}},{key:"item.currency_conversion",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',{attrs:{"outlined":"","rounded":"","dense":"","hide-details":"","type":"number"},on:{"change":function($event){return _vm.setPeriod(item)}},model:{value:(item.currency_conversion),callback:function ($$v) {_vm.$set(item, "currency_conversion", $$v)},expression:"item.currency_conversion"}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.showDeletePeriod(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")])],1)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mt-2",attrs:{"color":"secondary","rounded":"","outlined":"","small":""},on:{"click":_vm.addPeriod}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("add_period"))+" ")],1)],1)]},proxy:true}],null,false,3907628339)})],1),_c('v-card-actions',{staticClass:"ps-5 pb-5 mt-4 justify-center"},[_c('v-btn',{staticClass:"pl-5 pr-5",attrs:{"color":"secondary","rounded":"","depressed":""},on:{"click":_vm.saveChanges}},[_vm._v(" "+_vm._s(_vm.$t("save_changes"))+" ")]),_c('v-btn',{staticClass:"pl-5 pr-5",attrs:{"text":"","rounded":"","depressed":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)]:[_c('div',{staticClass:"pa-5"},[_vm._l((5),function(i){return _c('v-skeleton-loader',{key:i,staticClass:"elevation-0 mb-3",attrs:{"type":"card","height":"30"}})}),_c('v-skeleton-loader',{attrs:{"type":"actions"}})],2)]],2)],1),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"max-width":"400"},model:{value:(_vm.show_delete_period),callback:function ($$v) {_vm.show_delete_period=$$v},expression:"show_delete_period"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline justify-center",attrs:{"center":""}},[_vm._v(" "+_vm._s(_vm.$t("are_you_sure"))+" ")]),(_vm.period_to_edit)?_c('v-card-text',{staticClass:"text-center justify-center",domProps:{"innerHTML":_vm._s(
          _vm.$t('sure_delete_currency_conversion_period', {
            period: _vm.period_to_delete_dates
          })
        )}}):_vm._e(),_c('v-card-actions',{staticClass:"ps-5 pb-5 justify-center"},[_c('v-btn',{staticClass:"pl-5 pr-5",attrs:{"color":"red","rounded":"","depressed":"","text":""},on:{"click":function($event){return _vm.deletePeriod()}}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]),_c('v-btn',{staticClass:"pl-5 pr-5",attrs:{"color":"","text":"","rounded":"","depressed":""},on:{"click":_vm.hideDeletePeriod}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }